@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/fonts/sans' as *;
@use '@carbon/styles/scss/grid/flexbox';
@import './mixins.scss';
@import './overrides.scss';

.landing-page__illo {
    max-width: 100%;
}

.landing-page__banner {
    padding-top: $spacing-05;
    padding-bottom: $spacing-05 * 4;
    @include landing-page-background;
  }
  
  .landing-page__heading {
      @include type-style('productive-heading-05');
  }

  .landing-page__contactheading {
    @include type-style('productive-heading-05');
    padding-top: 2rem;
    padding-bottom: 2rem;
}

  .landing-page__r2 {
      margin-top: rem(-40px);
  }

  .landing-page__tab-content {
      padding-top: $spacing-10;
      padding-bottom: $spacing-10;
  }

  .landing-page__subheading {
      @include type-style('productive-heading-03');
      margin-bottom: 1rem;
  }

  .landing-page__label {
      @include type-style('heading-01');
  }

  .landing-page__divider {
      background-color: #33b1ff;
      min-height: 2pt;
  }