@use '@carbon/styles/scss/theme' as *;
@use '@carbon/styles/scss/fonts/sans' as *;

.seperator {
    min-height: 2px;
    background-color: #e0e0e0;
    margin-top: 2rem;
}

.footer {
    margin-left: 1rem;
    margin-top: 4rem;
}