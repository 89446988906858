// Use the following link to update button color tokens. 
//@use '@carbon/styles/scss/components/button/_tokens.scss';
@use './ThemeStuff/company-theme.scss';


@use '@carbon/react/scss/themes' as *;
@use '@carbon/react/scss/theme' with (
  $theme: company-theme.$my-theme,
);
@use '@carbon/react';
// @use '@carbon/styles/scss/grid/flexbox';

@import './components/MainHeader/MainHeader.scss';
@import './components/Footer/footer.scss';
@import './content/LandingPage/landing-page.scss';

.dot {
  display: inline-block;
  font-weight: bold;
  color: #dd156e;
}

.dot-blue {
  display: inline-block;
  font-weight: bold;
  color: #33B1FF;
}

:root {
  --cds-button-primary: #33B1FF !important;
  --cds-button-primary-hover: #2b9adf !important;
  --cds-button-primary-active: #2480b9 !important;
} 