.logo-box {
    padding: 2pt;
}

.Switcher-module--divider {
    border-bottom: 1px solid #525252;
    color: #525252;
    margin: 32px 1rem 8px;
    padding-bottom: 4px;
  }

  .Switcher-module--text span {
    color: #c6c6c6;
    font-size: var(--cds-label-01-font-size,.75rem);
    font-weight: var(--cds-label-01-font-weight,400);
    letter-spacing: var(--cds-label-01-letter-spacing,.32px);
    line-height: var(--cds-label-01-line-height,1.33333);
  }