@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/theme';

@mixin landing-page-background() {
    background-color:  theme.$background;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: -$spacing-06;
        top: 0;
    }
}